import useTranslation from "@cospex/client/hooks/useTranslation";
import { StyledLI, StyledUL } from "@cospex/client/tracker/components/List";
import { Stack, Typography, styled } from "@mui/material";

const LI = styled(StyledLI)`
  list-style: inside;
`;

export default function ServiceCompatible() {
  const { t } = useTranslation();
  return (
    <Stack
      sx={{
        flexDirection: { xs: "column", sm: "row" },
        p: "2rem 3rem",
        mb: "2rem",
      }}
    >
      <Stack flex="1 1 0"></Stack>
      <Stack
        flex="1 1 0"
        rowGap="1rem"
        sx={{
          backgroundColor: "white",
          borderRadius: "12px",
          p: "2rem",
          textAlign: "center",
        }}
      >
        <Typography variant="h2">{t("home-service-compat-title")}</Typography>
        <Typography>{t("home-service-compat-desc")}</Typography>
        <br />
        <Typography variant="h5">
          <b>{t("home-service-compat-why")}</b>
        </Typography>
        <StyledUL sx={{ mt: 0 }}>
          <LI>
            <b>{t("home-service-locate-one-title")}</b>
            {t("home-service-locate-one-desc")}
          </LI>
          <LI>
            <b>{t("home-service-locate-two-title")}</b>
            {t("home-service-locate-two-desc")}
          </LI>
          <LI>
            <b>{t("home-service-locate-three-title")}</b>
            {t("home-service-locate-three-desc")}
          </LI>
        </StyledUL>
        <Typography>{t("home-service-locate-trust")}</Typography>
      </Stack>
    </Stack>
  );
}
